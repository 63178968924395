#modal-newsletter .modal__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0;
  padding: 30px 25px 47px; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__body {
      padding: 8px 20px 4px; } }

#modal-newsletter .modal__button {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.58px;
  line-height: 17px;
  margin: 42px auto 0;
  padding: 19px 56px;
  text-transform: none; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__button {
      font-size: 11px;
      line-height: 14px;
      margin-top: 16px;
      padding: 9px 35px; } }

#modal-newsletter .modal__container {
  width: 915px; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__container {
      width: 100%; } }

#modal-newsletter .modal__content {
  display: flex;
  flex-direction: row;
  padding: 0; }

#modal-newsletter .modal__content-form--newsletter {
  padding: 20px;
  width: 470px; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__content-form--newsletter {
      width: 100%; } }

#modal-newsletter .modal__form {
  margin-top: 36px; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__form {
      margin-top: 17px; } }

#modal-newsletter .modal__header {
  border: none;
  justify-content: flex-end;
  padding: 0; }

#modal-newsletter .modal__image-container {
  flex: auto; }

#modal-newsletter .modal__input:not(:first-child) {
  margin-top: 26px; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__input:not(:first-child) {
      margin-top: 15px; } }

#modal-newsletter .modal__text {
  color: #1c1417;
  font-weight: 250;
  letter-spacing: .03rem;
  line-height: 34px;
  margin-top: 17px;
  text-align: center; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__text {
      font-size: 11px;
      line-height: 12px;
      margin-top: 13px; } }
  #modal-newsletter .modal__text--small {
    line-height: 25px;
    margin-top: 26px; }
    @media (max-width: 767px) {
      #modal-newsletter .modal__text--small {
        font-size: 9px;
        line-height: 12px;
        margin-top: 15px; } }

#modal-newsletter .modal__title {
  color: #4F4A47;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: .04rem;
  line-height: 34px;
  margin-right: 0;
  text-align: center; }
  @media (max-width: 767px) {
    #modal-newsletter .modal__title {
      font-size: 20px;
      line-height: 24px; } }
